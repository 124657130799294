import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    show: true,
    unfoldable: false,
  },
  reducers: {
    show: (state, action) => {
      state.show = action.payload
    },

    unfoldable: (state, action) => {
      state.unfoldable = action.payload
    },
  }
})

export const { show, unfoldable } = sidebarSlice.actions

export default sidebarSlice.reducer