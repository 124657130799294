import { createSlice } from '@reduxjs/toolkit'

export const logoutSlice = createSlice({
  name: 'logout',
  initialState: {
    redirect: false,
  },
  reducers: {
    set: (state, action) => {
      state.redirect = action.payload
    },
  }
})

export const { set } = logoutSlice.actions

export default logoutSlice.reducer