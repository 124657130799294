import { configureStore } from '@reduxjs/toolkit'

import alertReducer from './reducer/alert'
import sidebarReducer from './reducer/sidebar'
import loadingReducer from './reducer/loading'
import logoutReducer from './reducer/logout'

export default configureStore({
  reducer: {
    alert: alertReducer,
    sidebar: sidebarReducer,
    loading: loadingReducer,
    logout: logoutReducer,
  },
})