import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    show: false,
  },
  reducers: {
    show: (state, action) => {
      state.show = action.payload
    },
  }
})

export const { show } = loadingSlice.actions

export default loadingSlice.reducer