import { createSlice } from '@reduxjs/toolkit'

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    show: false,
    error: false,
    message: '',
    onClose: null,
  },
  reducers: {
    set: (state, action) => {
      const { show, error, message } = action.payload

      state.show = show
      state.error = error
      state.message = message
      state.onClose = action.payload.onClose || null
    },
  }
})

export const { set } = alertSlice.actions

export default alertSlice.reducer